<template>
  <div class="MyHomework">
    <el-tabs v-model="activeName">
      <el-tab-pane label="实训任务" name="实训任务">
        <table-page-template v-bind="templateConfig">
          <template #tableOperate="{ item }">
            <el-link type="primary" :underline="false" @click="toDetail(item)">{{ item.status === "未完成" ? "提交" : "修改" }}</el-link>
          </template>
        </table-page-template>
      </el-tab-pane>
      <el-tab-pane label="教学资源" name="教学资源">
        <table-page-template v-bind="resourceConfig">
          <template #tableOperate="{ item }">
            <el-link type="primary" :underline="false" @click="onpreview(item.url)">预览</el-link>
          </template>
        </table-page-template>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import TablePageTemplate from "components/common/TablePageTemplate";

export default {
  name: "MyHomework",
  components: {
    TablePageTemplate,
  },
  props: {},
  data() {
    return {
      activeName: "实训任务",
      list: [
        {
          index: 1,
          workName: "设计一个短视频推广",
          status: `未完成`,
          type: "客观题",
          createTime: "2023-03-22 17:47:49",
          beginTime: "2023-03-22 17:47:50",
          endTime: "2023-04-30 00:00:00",
        },
        {
          index: 2,
          workName: "画中画剪辑实训",
          status: `已完成`,
          type: "主观题",
          createTime: "2023-02-20 14:57:35",
          beginTime: "2023-02-20 14:57:40",
          endTime: "2023-04-30 00:00:00",
        },
        {
          index: 3,
          workName: "美妆类短视频脚本设计实训",
          status: `已批阅`,
          type: "客观题",
          createTime: "2023-02-08 15:30:48",
          beginTime: "2023-02-08 15:30:50",
          endTime: "2023-02-20 14:56:52",
        },
      ],
      resource: [
        {
          index: 1,
          name: "直播的兴起实训",
          describe: `直播发展历程以及各阶段的特点`,
          type: "PDF",
          url: "uploads/system/prepare.pdf",
        },
        {
          index: 2,
          name: "认识直播电商实训",
          describe: `直播电商与其他购物形式的对比`,
          type: "PDF",
          url: "uploads/system/plan.pdf",
        },
        {
          index: 3,
          name: "直播电商的准备实训",
          describe: `了解不同直播平台个人权限开通流程`,
          type: "PDF",
          url: "uploads/system/rise.pdf",
        },
        {
          index: 4,
          name: "电商直播策划实训",
          describe: `使用电商直播实训系统，分析商品数据，进行选品`,
          type: "PDF",
          url: "uploads/system/know.pdf ",
        },
      ],
    };
  },
  computed: {
    viewList() {
      return this.list;
    },
    templateConfig() {
      return {
        rightConfig: null,
        tableData: this.viewList,
        tableConfig: [
          { label: `任务名称`, prop: "workName", width: 350 },
          { label: "任务状态", prop: "status" },
          { label: "任务类型", prop: "type" },
          { label: "创建时间", prop: "createTime" },
          { label: "开始时间", prop: "beginTime" },
          { label: "结束时间", prop: "endTime" },
          { label: "操作", width: 100, align: "center" },
        ],
      };
    },
    resourceConfig() {
      return {
        rightConfig: null,
        tableData: this.resource,
        tableConfig: [
          { label: `排序`, prop: "index", width: 220 },
          { label: "资源名称", prop: "name" },
          { label: "资源简介", prop: "describe" },
          { label: "文件类型", prop: "type" },
          { label: "操作", width: 100, align: "center" },
        ],
      };
    },
  },
  methods: {
    toDetail(item) {
      if (item.type === "主观题" && item.status !== "已批阅") return this.$message.error("当前状态不可修改！");
      this.$router.push({
        path: "/studentHome/homeWork/detail",
        query: {
          id: item.index,
        },
      });
    },
    onpreview(url) {
      window.open("http://zblive.e-class.me/" + url);
    },
  },
};
</script>

<style scoped lang="scss">
.MyHomework {
  box-sizing: border-box;
  height: 100%;
  padding: 30px;
}
</style>
